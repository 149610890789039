import * as React from 'react'
import { useState, useEffect } from 'react'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { brfs } from '/src/styles/hitta-brf.module.css'

const HittaBrfPage = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([]);

	const getData = () => {
		fetch('https://api-v2-prod.brfify.se/brfs', {
			headers : {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
		.then(function(response) {
			//console.log(response)
			if(response.status === 200) {
				setIsLoading(false)
			}
			return response.json();
		})
		.then(function(myJson) {
			//console.log(myJson);
			setData(myJson)
		});
	}


	useEffect(() => {
		getData()
	}, [])


	return (
		<Layout pageTitle="Hitta fastighet">

			<p style={{ maxWidth: 650, margin: '0px auto' }}>
				Här listas de bostadsrättsföreningar och fastighetsägare som valt att listas publikt. Om du inte hittar din fastighetsägare vänligen kontakta dem.
			</p>

			<p>&nbsp;</p>

			{isLoading &&
				<div className="loader">
					<FontAwesomeIcon icon={faSpinner} style={{height: '52px'}} size="1x" />
				</div>
			}
			{!isLoading &&
			<div className={brfs}>
				{
					data && data.map((item) => {
						return(
							<a href={'https://'+item['alias']+'.brfify.se'} target="_blank">
								{item['image'] && <img src={item['image']} /> }
								<p>{item['name']}</p>
							</a>
						)
					})
				}
			</div>
			}
		</Layout>
	)
}

export default HittaBrfPage